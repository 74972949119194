

.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #000000;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.canvasContainer {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
